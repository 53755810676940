<template>
  <div class="sign-in">
    <el-form :model="form" status-icon :rules="rules" ref="form" label-width="80px" label-suffix=":">
      <div class="cloud-logo">
        <img src="@/assets/logo.jpg" />
      </div>
      <el-form-item label="Email" prop="email">
        <el-input v-model="form.email" type="text"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="pass">
        <el-input type="password" v-model="form.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item class="captcha" label="Captcha" prop="captcha">
        <el-input v-model="form.captcha" type="text"></el-input>
        <span v-html="captcha"></span>
        <el-button @click="getCaptcha" icon="el-icon-refresh" circle></el-button>
      </el-form-item>
      <el-form-item>
        <el-button :loading="loading" type="primary" @click="submitForm('form')">Login</el-button>
      </el-form-item>
      <p><el-button type="text" @click="$router.push('/forgot')">Forgot Password?</el-button></p>
    </el-form>
  </div>
</template>

<script>
import md5 from 'js-md5'
import axios from 'axios'
export default {
  data () {
    return {
      loading: false,
      captcha: '',
      form: {
        pass: '',
        email: '',
        captcha: '',
        captchaId: ''
      },
      rules: {
        pass: [
          { required: true, trigger: 'blur' }
        ],
        captcha: [
          { required: true, trigger: 'blur' }
        ],
        email: [
          { required: true, trigger: 'blur' },
          // { type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  mounted: function () {
    const token = localStorage.getItem('token')
    this.getCaptcha()
    if (token) {
      this.$store.httpDispatch({})('signinByToken').then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.$store.dispatch('setUser', { user: data.user })
          this.$router.push({
            path: '/work'
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  methods: {
    getCaptcha () {
      this.$store.httpDispatch({})('getCaptcha').then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.captcha = data.data
          this.captchaId = data.captchaId
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    submitForm (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.httpDispatch({})('signin', {
            email: this.form.email,
            password: md5(this.form.pass),
            captchaId: this.captchaId,
            captcha: this.form.captcha
          }).then((res) => {
            const { code, data } = res.data
            this.loading = false
            if (code === 0) {
              data.tokenExpiredAt = new Date(data.tokenExpiredAt)
              this.$store.dispatch('setUser', { user: data.user })
              localStorage.setItem('token', data.token)
              this.$store.commit('ACCOUNT_RESTORE', {
                tokenExpiredAt: data.tokenExpiredAt,
                token: data.token
              })
              localStorage.setItem('tokenExpiredAt', data.tokenExpiredAt.getTime())
              this.$router.push({
                path: '/work' 
              })
            } else {
              this.getCaptcha()
              return  that.$notify({
                title: data.msg,
                type: 'Error'
              })
            }
          }).catch((error) => {
            this.loading = false
            this.getCaptcha()
            console.log(error)
            if (error.data) {
              console.log(error.data)
              if (error.data.code === 4010001) {
                that.$notify({
                  title: `[${error.data.code}]Incorrect account or password`,
                  type: 'warning'
                })
              } else if (error.data.code === 4040101) {
                that.$notify({
                  title: `[${error.data.code}]:Account not exist`,
                  type: 'warning'
                })
              } else {
                that.$notify({
                  title: `[${error.data.code}]:${error.data.msg}`,
                  type: 'warning'
                })
              }
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style>
.cloud-logo {
  width: 100%;
  text-align: center;
  padding: 0px 0 20px;
}
.cloud-logo img {
  width: 250px;
}
.sign-in {
  width: 400px;
  margin: 0 auto;
  position: relative;
  top: 80px;
  padding: 50px 50px 50px;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  border: 1px solid #DFDFDF;
}
.sign-in .el-button {
  width: 100%
}
.captcha .el-input {
  float: left;
  width: 170px;
}
.captcha .el-button {
  width: 40px;
  float: right;
}
</style>
